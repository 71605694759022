import {
  newTracker,
  trackPageView,
  enableActivityTracking,
} from "@snowplow/browser-tracker";

const snowplow = {
  collector: "snowplow.wetransfer.com",
  cookieName: "_wt_snowplow",
};

newTracker("co", `${snowplow.collector}`, {
  bufferSize: 1,
  post: true,
  encodeBase64: false,
  respectDoNotTrack: true,
  userFingerprint: false,
  appId: "brand_home",
  platform: "web",
  stateStorageStrategy: "cookie",
  contexts: {
    webPage: true,
  },
  cookieName: `${snowplow.cookieName}`,
  discoverRootDomain: true,
});

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview",
        page: {
          path: location.pathname,
        },
      });
    }
  }
  enableActivityTracking({
    minimumVisitLength: 10,
    heartbeatDelay: 15,
  });
  trackPageView();
};
